import {
  Plus,
  CirclePlus,
  Edit,
  Upload,
  Delete,
  Check,
  Select,
  OfficeBuilding,
  Download,
  Document,
  Tickets,
  View,
  User,
  Refresh,
  Operation,
  RefreshLeft,
  DocumentDelete,
  DocumentAdd,
  FolderChecked,
} from '@element-plus/icons-vue'

export default [
  { type: 'page', data: { emit: 'create', label: '添加', type: 'primary', icon: Plus } },
  { type: 'page', data: { emit: 'import', label: '导入', type: 'success', icon: Upload } },
  { type: 'page', data: { emit: 'historyImport', label: '历史记录导入', type: 'success', icon: Upload } },
  { type: 'page', data: { emit: 'export', label: '导出', type: 'success', icon: Download } },
  { type: 'page', data: { emit: 'demand', label: '申请要求', type: 'primary', icon: Document } },
  { type: 'page', data: { emit: 'batchDel', label: '批量删除', type: 'primary', icon: RefreshLeft } },
  { type: 'page', data: { emit: 'batchAssign', label: '批量分配', type: 'primary', icon: Operation } },
  { type: 'page', data: { emit: 'save', label: '保存', type: 'primary' } },
  { type: 'page', data: { emit: 'default', label: '设为默认', type: 'primary' } },
  { type: 'page', data: { emit: 'deleteTemplate', label: '删除模版', type: 'danger' } },
  { type: 'page', data: { emit: 'printAssetTag', label: '打印资产标签', type: 'primary' } },
  { type: 'page', data: { emit: 'contractTemplate', label: '合同模版下载', type: 'success', icon: Download } },

  { type: 'table', data: { emit: 'manage', label: '楼层管理', type: 'primary', icon: OfficeBuilding } },
  { type: 'table', data: { emit: 'update', label: '编辑', type: 'primary', icon: Edit } },
  { type: 'table', data: { emit: 'delete', label: '删除', type: 'danger', icon: Delete } },
  { type: 'table', data: { emit: 'discard', label: '废弃', type: 'danger' } },
  { type: 'table', data: { emit: 'issued', label: '发布', type: 'primary' } },
  { type: 'table', data: { emit: 'verify', label: '审核', type: 'primary', icon: Select } },
  { type: 'table', data: { emit: 'checkVerify', label: '入住审核', type: 'primary', icon: User } },
  { type: 'table', data: { emit: 'approve', label: '审批', type: 'primary', icon: User } },
  { type: 'table', data: { emit: 'reovke', label: '撤销', type: 'danger', icon: RefreshLeft } },
  { type: 'table', data: { emit: 'redeem', label: '兑换', type: 'primary' } },
  { type: 'table', data: { emit: 'authed', label: '数据权限', type: 'primary', icon: User } },
  { type: 'table', data: { emit: 'viewer', label: '查看', type: 'primary', icon: View } },
  { type: 'table', data: { emit: 'viewerOrder', label: '查看清单', type: 'primary', icon: View } },
  { type: 'table', data: { emit: 'detail', label: '详情', type: 'primary', icon: View } },
  { type: 'table', data: { emit: 'locking', label: '锁定', type: 'primary', icon: View } },
  { type: 'table', data: { emit: 'cancelLocking', label: '取消锁定', type: 'primary', icon: View } },
  { type: 'table', data: { emit: 'password', label: '重置密码', type: 'primary', icon: Refresh } },
  { type: 'table', data: { emit: 'createSub', label: '添加子级', type: 'primary', icon: CirclePlus } },
  { type: 'table', data: { emit: 'result', label: '处置结果', type: 'primary', icon: View } },
  { type: 'table', data: { emit: 'access', label: '发起准入审批', type: 'primary' } },
  { type: 'table', data: { emit: 'accessDetail', label: '审批详情', type: 'primary', icon: View } },

  { type: 'table', data: { emit: 'renew', label: '续签', type: 'primary' } },
  { type: 'table', data: { emit: 'urging', label: '催办', type: 'primary' } },
  { type: 'table', data: { emit: 'finish', label: '完成', type: 'primary' } },
  { type: 'table', data: { emit: 'status', label: '变更状态', type: 'primary' } },
  { type: 'table', data: { emit: 'launch', label: '发起审批', type: 'primary' } },
  { type: 'table', data: { emit: 'checkLaunch', label: '发起入住审批', type: 'primary' } },
  { type: 'table', data: { emit: 'customRentFree', label: '自定义明细', type: 'primary' } },
  { type: 'table', data: { emit: 'rentFree', label: '免租配置', type: 'primary' } },
  { type: 'table', data: { emit: 'enable', label: '启用', type: 'primary' } },
  { type: 'table', data: { emit: 'assign', label: '分配', type: 'primary' } },
  { type: 'table', data: { emit: 'follow', label: '跟进', type: 'primary' } },
  { type: 'table', data: { emit: 'signed', label: '签订合同', type: 'primary' } },
  { type: 'table', data: { emit: 'forbid', label: '禁止', type: 'primary' } },
  { type: 'table', data: { emit: 'enable', label: '启用', type: 'primary' } },
  { type: 'table', data: { emit: 'cancel', label: '取消工单', type: 'primary', icon: DocumentDelete } },
  { type: 'table', data: { emit: 'dispose', label: '处置', type: 'primary', icon: FolderChecked } },
  { type: 'table', data: { emit: 'release', label: '上架', type: 'primary' } },
  { type: 'table', data: { emit: 'unrelease', label: '下架', type: 'primary' } },
  { type: 'table', data: { emit: 'transfer', label: '转移', type: 'primary' } },
  { type: 'table', data: { emit: 'receive', label: '领取', type: 'primary' } },
  { type: 'table', data: { emit: 'changeUser', label: '变更使用人', type: 'primary' } },
  { type: 'table', data: { emit: 'transferRecords', label: '流转记录', type: 'primary' } },
  { type: 'table', data: { emit: 'dutyRecord', label: '值班记录', type: 'primary', icon: Tickets } },
  { type: 'table', data: { emit: 'distribute', label: '下发', type: 'primary', icon: DocumentAdd } },
  { type: 'table', data: { emit: 'startverify', label: '发起审核' } },
  { type: 'table', data: { emit: 'renovation', type: 'primary', label: '装修' } },
  { type: 'table', data: { emit: 'editAudit', type: 'primary', label: '发起编辑审核' } },
  { type: 'table', data: { emit: 'cancelLeaseReport', type: 'primary', label: '发起退租申请' } },
  { type: 'table', data: { emit: 'auditEditReport', type: 'primary', label: '审核编辑上报', icon: Select } },
  { type: 'table', data: { emit: 'checkout', type: 'primary', label: '退租' } },
  { type: 'table', data: { emit: 'viewCheckout', label: '查看退租申请', type: 'primary' } },
  { type: 'table', data: { emit: 'uploadVoucher', label: '上传凭证', type: 'primary' } },
  { type: 'table', data: { emit: 'generateLicense', label: '生成许可证', type: 'primary' } },
  { type: 'table', data: { emit: 'checkAudit', label: '验收审核', type: 'primary' } },
  { type: 'table', data: { emit: 'refund', label: '退款', type: 'primary' } },
  { type: 'table', data: { emit: 'stop', label: '终止', type: 'primary' } },
  { type: 'table', data: { emit: 'stopProtocol', label: '生成终止协议', type: 'primary' } },
  { type: 'table', data: { emit: 'collection', label: '确认收款', type: 'primary' } },
  { type: 'table', data: { emit: 'deal', label: '工单处理', type: 'primary', icon: Select } },

  { type: 'table', data: { emit: 'addbtn', label: '添加按钮', type: 'primary' } },
]
